<template>
  <div class="searchPageBox">
    <!-- 已选择好的搜索条件啊 -->
    <div class="selectedBox">
      全部结果<i class="el-icon-arrow-right" />
      <span v-for="(item, index) in currentCategoryName" :key="item.code"><span v-if="item.goodsName" class="item">{{ item.goodsName }}<i class="el-icon-close" @click="removeCurrentCategory(index)" /></span></span>
      <span v-if="currentCategoryName.length > 0" class="clear-search" @click="removeCurrentCategory(0)">清空筛选</span>
    </div>
    <table class="searchTable">
      <!-- 商品类别 -->
      <tr v-for="item in goodsType === '01' ? (searchConditionList && searchConditionList[0] ? searchConditionList[0].children : []) : (searchConditionList && searchConditionList[1] ? searchConditionList[1].children : [])" :key="item.code">
        <td class="front">
          <p>{{ item.goodsName }}</p>
        </td>
        <td>
          <div class="contentBox" :style="showMore.indexOf(item.code) !== -1 ? 'height:auto;overflow: auto;' : 'height:43px;overflow: hidden;'">
            <span v-for="(jtem,index) in item.children" :key="jtem.code" :class="activeIndex.indexOf(jtem.code) !== -1 ? 'active productName elp-1' : 'productName elp-1'" :title="jtem.goodsName" @click="selectCategory(jtem, index)">{{ jtem.goodsName }}</span>
          </div>
        </td>
        <td class="more">
          <p v-if="item.children.length>13" :class="showMore.indexOf(item.code) !== -1 ? 'active' : ''" @click="clickShowMore(item)">
            {{ showMore.indexOf(item.code) !== -1 ? '收起' : '展开' }}<i class="el-icon-arrow-down" />
          </p>
        </td>
      </tr>
      <tr>
        <td class="front">
          <p>属性</p>
        </td>
        <td>
          <div class="contentBox specifications" @mouseleave="specActiveName = null">
            <ul class="">
              <li v-for="(item, index) in specificationsList" :key="item.id" :class="{'active' : index + 1 === specActiveName}" @click="handleClickSpec(index)">
                <p>
                  {{ item.name }}
                  <i class="el-icon-arrow-down" />
                </p>
              </li>
            </ul>
            <div :class="['specifications-child-box', {'child-block' : specActiveName}]">
              <template v-if="specActiveName === 1">
                <span v-for="cl in goodsMaterialOption" :key="cl.materials" :class="{'active' : currentCategoryName[1] && currentCategoryName[1].goodsName === cl.materials}" @click="getGoodsMaterialVal(cl.materials)">{{ cl.materials }}</span>
                <p v-show="goodsMaterialOption && goodsMaterialOption.length === 0">
                  暂无数据
                </p>
              </template>
              <template v-if="specActiveName === 2">
                <span v-for="cl in goodsSpecOption" :key="cl.specs" :class="{'active' : currentCategoryName[2] && currentCategoryName[2].goodsName === cl.specs}" @click="getGoodsSpecVal(cl.specs)">{{ cl.specs }}</span>
                <p v-show="goodsSpecOption && goodsSpecOption.length === 0">
                  暂无数据
                </p>
              </template>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { goodsGetGoodsCategoryList, goodsGetGoodsSpecList, goodsGetGoodsMaterialList } from '@/http/hangOut'
export default {
  props: {
    goodsType: {
      default: '01',
      type: String
    }
  },
  data() {
    return {
      // 品类数据
      searchConditionList: [],
      // 材质数据
      goodsMaterialOption: [],
      goodsMaterial: '',
      // 规格数据
      goodsSpecOption: [],
      goodsSpec: '',
      // 高亮
      activeIndex: '',
      // 展开
      showMore: [],
      // 当前已选择品类、材质、规格
      currentCategoryName: [{}, {}, {}],
      // 材质规格
      specificationsList: [{ name: '材质', id: '1' }, { name: '商品规格', id: '2' }],
      specActiveName: null
    }
  },
  watch: {
    $route: {
      handler(to, form) {
        if (to.query.goodsType !== (form && form.query && form.query.goodsType)) {
          this.currentCategoryName = []
          this.goodsSpecOption = this.goodsMaterialOption = []
          this.$emit('changeGoodsCondition', this.currentCategoryName)
        }
        this.activeIndex = to.query.code || ''
      },
      immediate: true
    }
  },
  created() {
    if (this.$route.query.goodsName || this.$route.query.code) {
      this.currentCategoryName.unshift({
        goodsName: this.$route.query.goodsName,
        code: this.$route.query.code
      })
      this.associativeSearch()
    }
    this.getGoodsGetGoodsCategoryList()
  },
  methods: {
    // 查询品类
    getGoodsGetGoodsCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.searchConditionList = res.data
      })
    },
    // 材质跟型号的搜索
    associativeSearch() {
      goodsGetGoodsMaterialList(this.currentCategoryName[0].code, res => {
        this.goodsMaterialOption = [...res.data]
      })
      goodsGetGoodsSpecList(this.currentCategoryName[0].code, res => {
        this.goodsSpecOption = [...res.data]
      })
    },
    unfoldingAndFolding() {},
    // 选择商品类别
    selectCategory(item) {
      //  如果已经选过当前品类 不用重复选择
      const findIndex = this.currentCategoryName.findIndex((val) => val.code === item.code)
      if (findIndex !== -1) return
      // 替换成最新选择的品类
      if (this.currentCategoryName.length > 0 && this.currentCategoryName[0].parentCode) {
        this.currentCategoryName[0] = item
      } else {
        // 第一次选择
        this.currentCategoryName.unshift(item)
      }
      this.activeIndex = item.code
      this.goodsMaterial = ''
      this.goodsSpec = ''
      this.currentCategoryName.splice(1, 2)
      this.associativeSearch()
      this.$emit('changeGoodsCondition', this.currentCategoryName)
      this.$router.replace({ query: { goodsType: this.$route.query.goodsType, goodsName: this.currentCategoryName[0].goodsName, code: this.currentCategoryName[0].code }})
    },
    // 移除当前已选品类
    removeCurrentCategory(index) {
      if (index === 0) {
        this.currentCategoryName = []
        this.goodsMaterialOption = []
        this.goodsSpecOption = []
        this.goodsMaterial = ''
        this.goodsSpec = ''
        this.activeIndex = ''
        this.specActiveName = null
        this.$emit('changeGoodsCondition', this.currentCategoryName)
        return
      }
      this.currentCategoryName.splice(index, 1)
      this.$emit('changeGoodsCondition', this.currentCategoryName)
    },
    // 展开事件
    clickShowMore(item) {
      const findIndex = this.showMore.findIndex((val) => val === item.code)
      if (findIndex !== -1) return this.showMore.splice(findIndex, 1)
      this.showMore.push(item.code)
    },
    // 获取当前选择材质
    getGoodsMaterialVal(val) {
      this.currentCategoryName.splice(1, 1, {
        type: 'material',
        code: val,
        goodsName: val
      })
      this.$emit('changeGoodsCondition', this.currentCategoryName)
    },
    // 获取当前选择规格
    getGoodsSpecVal(val) {
      if (this.currentCategoryName.length < 2) this.currentCategoryName.splice(1, 0, {})
      this.currentCategoryName.splice(2, 1, {
        type: 'spec',
        code: val,
        goodsName: val
      })
      this.$emit('changeGoodsCondition', this.currentCategoryName)
    },
    handleClickSpec(index) {
      this.specActiveName = index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.searchPageBox {
  width: 100%;

  .searchTable {
    display: block;
    width: 100%;
    padding: 6px 0 6px 20px;
    background-color: #fff;

    tr {
      height: 44px;
      overflow: hidden;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.04);
      }

      .more {
        vertical-align: top;
        width: 65px;
        line-height: 43px;
        color: $fontColor;
        font-size: 12px;

        p {
          cursor: pointer;
          i{
            transition: all .5s;
            font-size: 14px;
            margin-left: 8px;
          }
        }
        .active{
          color: $themeColors;
          i{
            color: $themeColors;
            transform: rotate(180deg);
          }
        }
      }

      .contentBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: $mainFont;
        font-size: 12px;
        margin-left: 8px;
        width: calc(100% - 8px);
        .productName{
          width: 60px;
          height: 43px;
          line-height: 43px;
        }
        .el-select{
          width: 180px;
          /deep/.el-input__inner{
            border: 0;
            outline: 0;
          }
        }

        span {
          margin-right: 20px;
          line-height: 36px;
          cursor: pointer;
        }
        .active{
          color: $themeColors;
        }
      }

      .front {
        vertical-align: top;
        font-size: 12px;
        color: $fontColor1;
        width: 56px;
        line-height: 43px;
        padding-right: 24px;
      }

    }

  }
}
.selectedBox {
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 16px;

    span {
      color: #666;
    }

  .item {
    display: flex;
    align-items: center;
    padding: 0 4px 0 8px;
    border: 1px solid $borderColor4;
    margin-left: 12px;
    height: 20px;

    i {
      font-size: 12px;
      color: #C1C1C1;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .item:first-of-type {
    margin-left: 4px;
  }

  .clear-search {
    cursor: pointer;
    margin-left: 8px;
  }
}
.specifications{
 position: relative;
 display: block;
 min-height: 44px;
 height: auto;
 z-index: 1;
 margin-left: -12px!important;
  ul {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    z-index: 2;
  }
  li {
    cursor: pointer;
    min-width: 82px;
    height: 43px;
    text-align: center;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    &.active {
      border: 1px solid $themeColors;
      border-bottom: 0;
      p {
        background-color: #fff;
        i {
          transform: rotateX(-180deg);
          transform: 0.2s;
        }
      }
    }
    p {
      width: 100%;
      height: 100%;
      line-height: 43px;
      position: absolute;
      top:1px
    }
  }
  .specifications-child-box {
    position: absolute;
    width: 1112px;
    box-sizing: border-box;
    top: 43px;
    background-color: #fff;
    z-index: 1;
    box-shadow: 2px 5px 6px rgba(100, 100, 100, 0.2);
    transition: 0.2s;
    &.child-block {
      padding: 10px 18px;
      border: 1px solid $themeColors;
    }
    span {
      display: inline-block;
      height: 34px;
      &:hover {
        color: $themeColors;
      }
      &.active {
        color: $themeColors;
      }
    }
  }
}

</style>
